//div:has(+ [data-testid="main.undo"]) {
//  display: none!important;
//}

[data-testid="quick-actions.undo"],
[data-testid="quick-actions.redo"],
[data-testid="quick-actions.redo"] ~ button,
[data-testid="quick-actions.redo"] ~ div
{
  display: none!important;
}

.tlui-page-menu__wrapper,
[data-testid="page-menu.button"] {
  width: 22rem!important;
  max-width: 22rem!important;
}

.whiteboard-message-bubble-counter-scale {
  transform: scale(calc(1/var(--tl-zoom)));
  transform-origin: center !important;
}

.tlui-style-panel {
  padding: 4px;
}

.tlui-style-panel .tlui-button-grid__four {
  grid-template-columns: 32px 32px 32px 32px;
  justify-items: center;
}

.tlui-style-panel .tlui-button-grid__three {
  grid-template-columns: 32px 32px 32px;
  justify-items: center;
}

.tlui-style-panel .tlui-slider__container,
.tlui-style-panel .tlui-style-panel__double-select-picker {
  width: 128px;
}

.tlui-style-panel .tlui-button-grid__button,
.tlui-style-panel .tlui-button {
  width: 36px;
  height: 36px;
  min-width: unset;
}

.tlui-style-panel .tlui-slider__track {
  height: 32px;
}

.tlui-help-menu__button {
  height: 32px;
  width: 32px;
}

.tlui-help-menu__button:before {
  top: 0 !important;
  left: 0 !important;
  height: 100%;
  width: 100%;
  border-width: 2px;
}

.tl-shape[data-shape-type="comment"] {
  height: 28px !important;
  width: 28px !important;
}

.tl-theme__dark {
  --color-background: rgb(24 33 47) !important;
}
