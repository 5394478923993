.av_multi_select_error__menu,
.av_multi_select__menu {
  @apply dark:bg-gray-800;
}

.av_multi_select_error__menu-list div,
.av_multi_select__menu-list div {
  @apply dark:hover:bg-gray-700;
}

.av_multi_select_error__option--is-focused,
.av_multi_select__option--is-focused {
  @apply dark:bg-gray-700;
}

.av_multi_select__control {
  @apply rounded-md shadow-sm bg-gray-50 border-gray-300 dark:bg-gray-800 dark:border-gray-700 #{!important};
}

.av_multi_select_error__control {
  @apply rounded-md shadow-sm bg-gray-50 dark:bg-gray-800 border-red-300 dark:border-red-700 placeholder-red-300 dark:placeholder-red-600 #{!important};
}

.av_multi_select__control--is-focused {
  @apply border-transparent bg-gray-50 dark:bg-gray-800 ring-[5px] ring-blue-200 ring-offset-0 outline-none #{!important};
}

.av_multi_select_error__control--is-focused {
  @apply border-transparent dark:border-transparent bg-gray-50 dark:bg-gray-800 ring-[5px] ring-red-500 ring-offset-0 outline-none #{!important};
}

.av_multi_select_error__multi-value__remove,
.av_multi_select__multi-value__remove {
  @apply flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white #{!important};
}

.av_multi_select_error__input,
.av_multi_select__input {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none dark:text-white #{!important};
}

.av_multi_select_error__indicator-separator,
.av_multi_select__indicator-separator {
  @apply dark:bg-gray-700;
}

.av_multi_select_error__multi-value,
.av_multi_select__multi-value {
  @apply bg-blue-500 bg-opacity-20 inline-flex items-center m-1 px-3 rounded-md #{!important};
}

.av_multi_select_error__multi-value__label,
.av_multi_select__multi-value__label {
  @apply dark:text-gray-200 text-gray-800 p-0 py-1.5 text-xs #{!important};
}

.av_multi_select_error__value-container--has-value,
.av_multi_select__value-container--has-value {
  @apply py-0 self-stretch;
}

.av_multi_select_error__value-container,
.av_multi_select__value-container {
  @apply px-0;
}

.av__control {
  @apply max-h-[50vh] overflow-y-auto;
}

// ----------------------------------------------------------

.new_av_error__menu,
.new_av__menu {
  @apply dark:bg-gray-800;
}

.new_av_error__option,
.new_av__option {
  @apply py-2 pl-8 pr-4 #{!important};
}

.new_av_error__option--is-focused,
.new_av__option--is-focused {
  @apply dark:bg-gray-700;
}

.new_av__control {
  @apply border-0 group-hover:bg-gray-200 dark:group-hover:bg-gray-800 leading-none text-sm sm:text-base cursor-pointer w-full bg-transparent rounded #{!important};
}


.new_av_error__control {
  @apply border-0 bg-red-100 placeholder-red-300 dark:bg-red-900 dark:placeholder-red-600 focus:border-none focus:ring-transparent #{!important};
}

.new_av_error__control--is-focused,
.new_av__control--is-focused {
  @apply border-transparent bg-gray-50 dark:bg-gray-800 ring-4 ring-blue-200 ring-offset-0 outline-none #{!important};
}


.new_av_error__input,
.new_av__input {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none dark:text-white #{!important};
}

.new_av_error__value-container.new_av_error__value-container--is-multi,
.new_av__value-container.new_av__value-container--is-multi {
  @apply p-px #{!important};
}

.new_av_error__value-container,
.new_av__value-container {
  @apply p-0 #{!important};
}

.new_av_error__indicator-separator,
.new_av__indicator-separator {
  @apply hidden;
}

.new_av_error__indicator,
.new_av__indicator {
  @apply pr-3 pl-2 py-2 text-gray-500 #{!important};
}

.new_av_error__multi-value,
.new_av__multi-value {
  @apply bg-blue-500 bg-opacity-20 inline-flex items-center m-1 px-3 rounded-md #{!important};
}

.new_av_error__multi-value__label,
.new_av__multi-value__label {
  @apply dark:text-gray-200 text-gray-800 p-0 py-1.5 text-xs #{!important};
}

.new_av_error__multi-value__remove,
.new_av__multi-value__remove {
  @apply flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white #{!important};
}

.new_av_error__single-value,
.new_av__single-value {
  @apply dark:text-gray-200 py-2 pr-2 pl-3;
}

.new_av_error__group,
.new_av__group {
  @apply p-0 #{!important};
}

.new_av_error__group-heading,
.new_av__group-heading {
  @apply m-0 pl-2 pr-4 pt-2 pb-1 #{!important};
}

.new_av_error__group-heading div,
.new_av__group-heading div {
  @apply p-0 #{!important};
}

//------------------------------------------------------------------------------------------

.av_select_error__group,
.av_select__group {
  @apply p-0  #{!important};
}
.av_select_error__group-heading,
.av_select__group-heading {
  @apply m-0 pl-8 pr-4 pt-2 pb-1 #{!important};
}

.av_select_error__group-heading div,
.av_select__group-heading div {
  @apply p-0 #{!important};
}

.av_select_error__option,
.av_select__option {
  @apply py-2 pl-8 pr-4 text-gray-800 dark:text-gray-200 cursor-default focus:text-gray-900 dark:focus:text-gray-100 focus:bg-gray-100 dark:focus:bg-gray-900 select-none  focus:ring-base focus:ring-offset-0 #{!important};
}

.av_select_error__option:active,
.av_select__option:active {
  @apply bg-gray-200 dark:bg-gray-700 #{!important};
}

.av_select_error__option--is-selected,
.av_select__option--is-selected {
  @apply relative bg-transparent dark:bg-transparent font-semibold #{!important};
  @apply before:absolute before:h-5 before:w-5 before:left-1.5
}

.av_select_error__option--is-selected:before,
.av_select__option--is-selected:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%233b82f6' class='h-5 w-5'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
}

.av_select_error__option--is-focused,
.av_select__option--is-focused {
  @apply bg-gray-200 dark:bg-gray-700 #{!important};
}

.av_select_error__menu,
.av_select__menu {
  @apply dark:bg-gray-800 w-auto #{!important};
}

.av_select__control {
  @apply min-h-0 rounded-none bg-transparent cursor-pointer border-0 border-b dark:hover:border-gray-700 #{!important};
  @apply border-gray-300 dark:border-gray-700;
}

.av_select_error__control{
  @apply min-h-0 rounded-none bg-transparent cursor-pointer border-0 border-b dark:hover:border-red-700 hover:border-red-500 border-red-500 dark:border-red-700 #{!important};
}

.av_select_error__indicator-separator,
.av_select__indicator-separator {
  @apply hidden;
}

.av_select_error__dropdown-indicator,
.av_select__dropdown-indicator {
  @apply p-0 #{!important};
}

.av_select_error__dropdown-indicator svg,
.av_select__dropdown-indicator svg {
  @apply h-4 w-4  #{!important};
}

.av_select_error__input-container,
.av_select__input-container {
  @apply my-0 py-0 #{!important};
}

.av_select_error__value-container,
.av_select__value-container {
  @apply py-0 #{!important};
}

.av_select__control--is-focused {
  @apply shadow-none border-blue-500 hover:border-blue-500 dark:border-blue-300 dark:hover:border-blue-300 #{!important};
}

.av_select_error__control--is-focused {
  @apply shadow-none border-red-500 hover:border-red-500 dark:border-red-700 dark:hover:border-red-700 #{!important};
}

.av_select_error__input,
.av_select__input {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none dark:text-white #{!important};

  .invert-input-color & {
    @apply dark:text-black #{!important};
  }
}

.av_select_error__single-value,
.av_select__single-value {
  @apply dark:text-white;

  .invert-input-color & {
    @apply dark:text-black #{!important};
  }
}

//------------------------------------------------------------------------------------------

.second_new_av_error__option,
.second_new_av__option {
  @apply py-2 pl-8 pr-4 #{!important};
}
.second_new_av_error__control,
.second_new_av__control {
  @apply min-h-0 bg-transparent cursor-pointer border-0 #{!important};
}
.second_new_av_error__control--is-focused,
.second_new_av__control--is-focused {
  @apply shadow-none #{!important};
}

.second_new_av_error__indicator-separator,
.second_new_av__indicator-separator {
  @apply hidden;
}

.second_new_av_error__dropdown-indicator,
.second_new_av__dropdown-indicator {
  @apply p-0 #{!important};
}

.second_new_av_error__input-container,
.second_new_av__input-container {
  @apply m-0 py-0 #{!important};
}

.second_new_av_error__value-container,
.second_new_av__value-container {
  @apply p-0 text-sm #{!important};
}

.second_new_av_error__input,
.second_new_av__input {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none dark:text-white #{!important};
}

.second_new_av_error__single-value,
.second_new_av__single-value {
  @apply m-0 dark:text-white text-sm #{!important};
}

.second_new_av_error__menu,
.second_new_av__menu {
  @apply dark:bg-gray-800;
}

.second_new_av_error__option--is-selected,
.second_new_av__option--is-selected {
  @apply relative text-inherit bg-transparent dark:bg-transparent font-semibold #{!important};
  @apply before:absolute before:h-5 before:w-5 before:left-1.5 before:top-2.5
}
.second_new_av_error__option--is-selected:before,
.second_new_av__option--is-selected:before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%233b82f6' %3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
}

.second_new_av_error__option:active,
.second_new_av__option:active {
  @apply bg-gray-200 dark:bg-gray-700 #{!important};
}

.second_new_av_error__option--is-focused,
.second_new_av__option--is-focused {
  @apply bg-gray-200 dark:bg-gray-700 #{!important};
}


//------------------------------------------------------------------------------------------

.av_select--custom-paddings .av_select_error__group-heading,
.av_select--custom-paddings .av_select__group-heading {
  @apply m-0 p-0 #{!important};
}


//.av_select--custom-paddings .av_select_error__option,
//.av_select--custom-paddings .av_select__option {
//  @apply py-2 pl-8 pr-4 #{!important};
//}

//.av_select_error__menu,
//.av_select__menu {
//  @apply dark:bg-gray-800 w-auto #{!important};
//}
