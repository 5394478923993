/* common styles */
.formContainer.formContainerCREDIT_CARD iframe {
  height: 370px;
  margin-bottom: 10px;
}

.formContainer[class*="formContainerregistered"] iframe {
  height: 100px;
  margin-bottom: 10px;
}

.payment-networks-container {
  padding-top: 16px;
  max-width: 20rem;
  margin: auto;
}

.formContainer.formContainerCREDIT_CARD {
  margin-left: -4px;
  margin-right: -14px;
}

.payment-networks-container input[type="radio"] {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  pointer-events: auto;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  -webkit-print-color-adjust: exact;
  background-origin: border-box;
  color: #2563eb;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, .05);
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 9999px;
}

.payment-networks-container input[type="radio"]:checked {
  color: rgb(37 99 235);
  border: 1px solid currentColor;
  appearance: none;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, .05);
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}

.payment-networks-container input[type="radio"]:focus {
  border: 1px solid rgb(147 197 253);
  appearance: none;
  box-shadow: 0 0 0 0 #fff, 0 0 0 4px rgb(147 197 253), 0 1px 2px 0 rgba(0, 0, 0, .05);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"] {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  border-collapse: collapse;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  -webkit-print-color-adjust: exact;
  background-origin: border-box;
  color: #2563eb;
  display: inline-block;
  flex-shrink: 0;
  padding: 0;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"]:focus {
  border-color: transparent;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(147, 197, 253) 0px 0px 0px 4px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: rgb(37 99 235);
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"]:checked {
  color: #2563eb;
  background-color: #2563eb;
  border: 1px solid #2563eb;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: .25rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"]+label {
  -webkit-text-size-adjust: 100%;
  font-family: Rubik, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  tab-size: 4;
  box-sizing: border-box;
  margin-left: .5rem;
  display: block;
  font-size: .875rem;
  line-height: 1.25rem;
}

.formContainer.formContainerCREDIT_CARD .registration.options {
  margin: 0 4px;
  padding-right: 10px;
}

.separator.network-separator {
  box-sizing: border-box;
  height: 0;
  margin: 0;
  border-width: 0px;
  border-top-width: 1px;
  border-style: dashed;
}

.delete-account:hover .delete-tooltip {
  position: absolute;
  right: 0;
  top: -10px;
  transform: translate3d(-100%, -100%, 0);
}

.no-style[class*="-separator"].list {
  font-family: Rubik, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

/* light theme */
.op-payment-widget-full-loading {
  background: #fff;
}

.op-payment-widget-full-loading .MuiCircularProgress-svg {
  color: #93c5fd;
}

.payment-networks-container {
  border-top: 1px solid #d4d4d8;
}

.payment-networks-container label.textLabel {
  color: #3f3f46;
}

.formContainer.formContainerCREDIT_CARD {
  color: #3f3f46;
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"] {
  border: 1px solid #d4d4d8;
  background: #fafafa;
}

.formContainer.formContainerCREDIT_CARD input[type="checkbox"]+label {
  color: #18181b;
}

.payment-networks-container input[type="radio"] {
  background-color: #fafafa;
  border-color: #d4d4d8;
}

.no-style[class*="-separator"].list {
  color: #18181b;
}

.separator.network-separator {
  border-color: #d4d4d8;
}


/* dark theme */
.dark {
  .op-payment-widget-full-loading {
    background: #262626;
  }
  
  .op-payment-widget-full-loading .MuiCircularProgress-svg {
    color: #93c5fd;
  }
  
  .payment-networks-container {
    border-top: 1px solid #3d3d3d;
  }
  
  .payment-networks-container label.textLabel {
    color: #d4d4d4;
  }
  
  .formContainer.formContainerCREDIT_CARD {
    color: #d4d4d4;
  }

  .formContainer.formContainerCREDIT_CARD input[type="checkbox"] {
    border: 1px solid #52525b;
    background: #262626;
  }
  
  .formContainer.formContainerCREDIT_CARD input[type="checkbox"] + label {
    color: #f4f4f5;
  }
  
  .payment-networks-container input[type="radio"] {
    background-color: #262626;
    border-color: #52525b;
  }
  
  .no-style[class*="-separator"].list {
    color: #f4f4f5;
  }
  
  .separator.network-separator {
    border-color: rgb(63 63 70);
  }

  .formContainer.formContainerCREDIT_CARD input[type="checkbox"]:checked {
    color: #2563eb;
    background-color: #2563eb;
    border: 1px solid #2563eb;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: .25rem;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
  }
}