@layer utilities {
  .snap {
    scroll-snap-type: var(--scroll-snap-direction, x) var(--scroll-snap-constraint, proximity);
  }
  .snap-y {
    --scroll-snap-direction: y;
  }
  .snap-x {
    --scroll-snap-direction: x;
  }
  .snap-mandatory {
    --scroll-snap-constraint: mandatory;
  }
  .snap-proximity {
    --scroll-snap-constraint: proximity;
  }
  .snap-start {
    scroll-snap-align: start;
  }
  .snap-center {
    scroll-snap-align: center;
  }
  .snap-end {
    scroll-snap-align: end;
  }
}
