.ril-outer.ril__outer.ril__outerAnimating {
  height: calc(100% - 128px);
  @apply bg-gray-950 bg-opacity-75 my-16;
}

.ril-toolbar.ril__toolbar,
.ril-caption.ril__caption {
  @apply bg-gray-950 bg-opacity-75 h-16;
}

ul.ril__toolbarSide {
  @apply flex space-x-0.5 p-3 h-auto;
}

li.ril-toolbar__item.ril__toolbarItem {
  @apply block leading-none overflow-visible;
}

.ril-toolbar-left.ril__toolbarSide.ril__toolbarLeftSide {
  @apply overflow-visible;
}

.ril-caption-content.ril__captionContent {
  @apply p-0 flex-grow;
}

button.ril__navButtons {
  @apply 2xl:w-32 4xl:w-48 h-full sm:w-24 w-1/4 bg-gray-950 opacity-0 hover:opacity-100 bg-opacity-50 focus:ring-0 active:bg-opacity-75 focus:ring-offset-0;
}

button.ril__toolbarItemChild.ril__builtinButton {
  @apply w-10 h-10 rounded-md focus:ring-white focus:ring-offset-0 hover:bg-white hover:bg-opacity-10 text-gray-300 hover:text-white;
}

button.ril__zoomInButton,
button.ril__zoomOutButton {
  @apply hidden sm:inline-block;
}

button.ril__builtinButtonDisabled {
  @apply opacity-25;
}

.ril-inner.ril__inner {
  @apply bg-gray-950 bg-opacity-60;
}