[class^='line-clamp-'] *,
[class*=' line-clamp-'] * {
  display: inline;
}
[class^='line-clamp-'] br,
[class*=' line-clamp-'] br,
[class^='line-clamp-'] hr,
[class*=' line-clamp-'] hr {
  display: none;
}
