
.animate-bg-to-r {
  animation-name: bg-to-r;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes bg-to-r {
  100%  { background-position: 60px 0; }
}

.cycle-to-b {
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-name: cycle-to-b;
  animation-play-state: running;
  animation-timing-function: ease-out
}

@keyframes cycle-to-b {
  50% {
    transform: translateY(100%);
  }

  50.01% {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
