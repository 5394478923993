.loader-wrap {
  @apply inline-flex items-center justify-center self-center;
}
.loader {
  @apply inline-flex relative;
  --tw-loader-step: 20px;
}
.loader > div {
  @apply h-3 w-3 rounded-full bg-current;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader > div:nth-child(1) {
  @apply absolute left-0;
  animation: loader-enter 0.6s infinite;
}
.loader > div:nth-child(2) {
  @apply mr-2;
  animation: loader-shift 0.6s infinite;
}
.loader > div:nth-child(3) {
  @apply mr-2;
  animation: loader-shift 0.6s infinite;
}
.loader > div:nth-child(4) {
  animation: loader-leave 0.6s infinite;
}

.loader-sm {
  --tw-loader-step: 12px;
}
.loader-sm > div {
  @apply h-2 w-2;
}
.loader-sm > div:nth-child(2) {
  @apply mr-1;
}
.loader-sm > div:nth-child(3) {
  @apply mr-1;
}

.loader-xs {
  --tw-loader-step: 8px;
}
.loader-xs > div {
  @apply h-1.5 w-1.5;
}
.loader-xs > div:nth-child(2) {
  @apply mr-0.5;
}
.loader-xs > div:nth-child(3) {
  @apply mr-0.5;
}

.loader-2xs {
  --tw-loader-step: 4px;
}

.loader-2xs>div {
  @apply h-1 w-1;
}

.loader-2xs>div:nth-child(2) {
  @apply mr-0.5;
}

.loader-2xs>div:nth-child(3) {
  @apply mr-0.5;
}

@keyframes loader-enter {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-leave {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-shift {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--tw-loader-step), 0);
  }
}