//.editor-container {
//  background: #fff;
//  margin: 20px auto 20px auto;
//  border-radius: 2px;
//  max-width: 600px;
//  color: #000;
//  position: relative;
//  line-height: 20px;
//  font-weight: 400;
//  text-align: left;
//  border-radius: 10px;
//}
//
//.editor-input {
//  min-height: 150px;
//  resize: none;
//  font-size: 15px;
//  caret-color: rgb(5, 5, 5);
//  position: relative;
//  tab-size: 1;
//  outline: 0;
//  padding: 10px 10px 5px;
//  caret-color: #444;
//}
//
//.editor-placeholder {
//  color: #999;
//  overflow: hidden;
//  position: absolute;
//  text-overflow: ellipsis;
//  top: 10px;
//  left: 10px;
//  font-size: 15px;
//  user-select: none;
//  display: inline-block;
//  pointer-events: none;
//}
//
//.editor-paragraph {
//  margin: 0 0 15px 0;
//  position: relative;
//}
//
//.dropdown .item .icon {
//  display: flex;
//  width: 20px;
//  height: 20px;
//  user-select: none;
//  margin-right: 12px;
//  line-height: 16px;
//  background-size: contain;
//  background-position: center;
//  background-repeat: no-repeat;
//}

.reset-anchor {
  top: initial!important;
  //top: auto;
  //bottom: auto;
  //bottom: initial!important;
  //flex-direction: column-reverse!important;
}

.mentions-menu {
  min-width: 250px;
  position: absolute;
  top: -200px;
}

.mention:focus {
  box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
  outline: none;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: auto;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}
