.dark .react-loading-skeleton {
  --base-color: #27272a;
  --highlight-color: #3f3f46;

  background-color: var(--base-color);
}

.dark .react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
}
