@layer utilities {
  .glow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(255, 255, 255, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow {
    --tw-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.1), 0 1px 2px 0 rgba(255, 255, 255, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-md {
    --tw-shadow: 0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(255, 255, 255, 0.1), 0 4px 6px -2px rgba(255, 255, 255, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(255, 255, 255, 0.1), 0 10px 10px -5px rgba(255, 255, 255, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(255, 255, 255, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(255, 255, 255, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }

  .glow-none {
    --tw-shadow: 0 0 #fff0;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff0), var(--tw-ring-shadow, 0 0 #fff0), var(--tw-shadow);
  }
}
