.av_error__menu,
.av__menu {
  @apply dark:bg-gray-800;
}

.av_error__menu-list .av_option,
.av__menu-list .av_option {
  @apply dark:hover:bg-gray-700;
}

.av_error__option--is-focused,
.av__option--is-focused {
  @apply dark:bg-gray-700;
}

.av__control {
  @apply rounded-md shadow-sm bg-gray-50 border-gray-300 dark:bg-gray-800 dark:border-gray-700 #{!important};
}

.av_error__control {
  @apply rounded-md shadow-sm bg-gray-50 dark:bg-gray-800 border-red-300 dark:border-red-700  placeholder-red-300 dark:placeholder-red-600  #{!important};
}

.av__control--is-focused {
  @apply border-transparent bg-gray-50 dark:bg-gray-800 ring-[5px] ring-blue-200 ring-offset-0 outline-none #{!important};
}

.av_error__control--is-focused {
  @apply border-transparent dark:border-transparent bg-gray-50 dark:bg-gray-800 ring-[5px] ring-red-500 ring-offset-0 outline-none #{!important};
}

.av_error__multi-value__remove,
.av__multi-value__remove {
  @apply flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white #{!important};
}

.av_error__input,
.av__input {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none dark:text-white #{!important};
}

.av_error__indicator-separator,
.av__indicator-separator {
  @apply dark:bg-gray-700;
}

.av_error__multi-value,
.av__multi-value {
  @apply bg-blue-500 bg-opacity-20 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md #{!important};
}

.av_error__multi-value__label,
.av__multi-value__label {
  @apply dark:text-gray-200 text-gray-800 p-0 text-xs #{!important};
}

.av_error__single-value,
.av__single-value {
  @apply dark:text-gray-200 flex items-center h-full;
}

.av_error__value-container--has-value,
.av__value-container--has-value {
  @apply py-0 self-stretch;
}

.av_error__value-container,
.av__value-container {
  @apply px-0;
}
