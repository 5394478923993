.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white dark:bg-gray-850 shadow rounded-full h-5 w-5 transition;
}

input:checked + .toggle-bg:after {
  transform: translateX(100%);
}

input:checked + .toggle-bg {
  @apply bg-green-400 dark:bg-green-500;
}