.basic-input {
  @apply appearance-none w-full px-3 py-2 border rounded-md shadow-sm sm:text-sm;
  @apply border-gray-300 placeholder-gray-500 focus:placeholder-gray-300 bg-gray-50;
  @apply dark:border-gray-700 dark:focus:placeholder-gray-600 dark:bg-gray-800 dark:text-gray-200;
}
.basic-input::placeholder {
  @apply italic;
}
.basic-input:-webkit-autofill {
  @apply border-transparent;
}
.basic-checkbox, .basic-radio {
  @apply appearance-none border h-5 w-5 shadow-sm;
  @apply border-gray-300 bg-gray-50 checked:text-blue-600 hover:text-blue-700 checked:border-current disabled:opacity-50 disabled:cursor-default;
  @apply dark:border-gray-700 dark:bg-gray-800 dark:checked:bg-current dark:checked:border-current;
}
.basic-checkbox {
  @apply rounded;
}
.basic-radio {
  @apply rounded-full;
}

.number-appearance-none {
    /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}

.rating-radio label {
	@apply text-yellow-500;
}
.rating-radio input:checked ~ label {
	@apply text-gray-400 dark:text-gray-600;
}
.rating-radio input:checked + label {
	@apply text-yellow-500  dark:text-yellow-500;
}
.rating-radio:hover input + label {
	@apply text-yellow-500  dark:text-yellow-500;
}
.rating-radio input:hover:not(:disabled) ~ label,
.rating-radio input:focus:not(:disabled) ~ label {
	@apply text-gray-400 dark:text-gray-600;
}
.rating-radio input:hover:not(:disabled) + label,
.rating-radio input:focus:not(:disabled) + label {
	@apply text-yellow-600 dark:text-yellow-400;
}
.rating-radio input:not(:disabled) + label {
  @apply cursor-pointer;
}

.focus-within\:ring-base {
  @apply focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-black focus-within:ring-offset-white dark:focus-within:ring-white dark:focus-within:ring-offset-gray-900;
}

.ring-base {
  @apply ring-2 ring-offset-2 ring-black ring-offset-white dark:ring-white dark:ring-offset-gray-900 outline-none;
}

.focus\:ring-base {
  @apply focus:ring-2 focus:ring-offset-2 focus:ring-black focus:ring-offset-white dark:focus:ring-white dark:focus:ring-offset-gray-900 focus:outline-none;
}

.focus-within\:ring-none {
  @apply focus-within:ring-0 focus-within:ring-offset-0 focus-within:outline-none;
}

.focus\:ring-none {
  @apply focus:ring-0 focus:ring-offset-0 focus:outline-none;
}
